import React from 'react';

const PioniersPage = props => {
  return (
<div className="de-stort-page boz pioniers">
    <div className="container">
        <h1>Pioneers</h1>
        <p className="intro">Users can apply and showcase their work, to become a featured pioneer on the Tegenlicht tv-show and online channels.
        </p>
        <p className="intro-small">The competition centers around social and environmental issues. Applicants from all over the Netherlands can apply and the audience can find and vote for their favorite pioneer. The jury selects a winner based on it’s idea, potential and amount of crowd votes. 
        </p>
        
        <div className="image-big">
            <img src="/images/pioniers/wireframe-list-desktop-min.jpg" alt="wireframe list pioneers"/>
        </div>
        <div className="image-big">
            <img src="/images/pioniers/list-view-desktop-min.jpg" alt="visual design list"/>
        </div>
        <div className="squares">
            <div className="square">
                <img src="/images/pioniers/wireframe-list-view-edities-min.jpg" alt="wireframe list mobile"/>
            </div>
            <div className="square">
                <img src="/images/pioniers/list-view-mobile-min.jpg" alt="visual design list mobile"/>
            </div>
            <div className="square">
                <img src="/images/pioniers/list-view-mobile-edities-min.jpg" alt="visual design list editions menu"/>
            </div>
        </div>


        <div className="middle">
            <h3>Overview UI</h3>
            <p className="description-big">An overview of all pioniers can be found in a map and list UI. The stakeholders wanted to show that idea’s and project are started everywhere in the country. But for mobile users the app wasn’t ideal. So I decided to create a list view, for quick name based navigation, and a map view for location based intel. The user can easily switch between views.
            </p>
            <p className="description-big">The stakeholder can now also use context-based views based on the state of the competition. For instance, when users can vote for their favorite pioneer, the listview is shown as default. When voting is closed, the map view becomes default to show location based info for user to discover every previous pioneer close to their location.
            </p>
        </div>
    </div>
    <div className="container">  
        <div className="image-big">
            <img src="/images/pioniers/wireframe-kaart-desktop-min.jpg" alt="wireframe map"/>
        </div>
        <div className="image-big">
            <img src="/images/pioniers/kaart-desktop-def.jpg" alt="visual design map"/>
        </div>
    </div>

    <div className="container">
        <div className="squares double">
            <div className="square">
                <img src="/images/pioniers/wireframe-kaart-mobile-min.jpg" alt="wireframe map mobile"/>
            </div>
            <div className="square">
                <img src="/images/pioniers/kaart-mobile-def.jpg" alt="visual design map mobile"/>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="middle">
            <h3>Submission form</h3>
            <p className="description-big">I first started making a flow for the pioneer-submissions. The stakeholders weren’t quite sure which information they’d need from the pioneer, so I started wireframing forms to figure out in a co-creation with the stakeholder, which information was necessary for the judges and audience.
            </p>
            <p className="description-big">The form is divided in four easy steps and has an overview at the end, which allows users to make changes if mistakes were made. 
            </p>
        </div>
         <div className="squares wide">
            <div className="square">
                <img src="/images/pioniers/aanmeld-formulier-desktop-min.jpg" alt="registration form desktop"/>
            </div>
            <div className="square">
                <img src="/images/pioniers/aanmeld-formulier-mobile-min.jpg" alt="registration form mobile"/>
            </div>
        </div>
    </div>

    <div className="container">
         <div className="squares wide">
            <div className="square">
                <img src="/images/pioniers/detailpagina-desktop-def.jpg" alt="detailpagina desktop"/>
            </div>
            <div className="square mobile">
                <img src="/images/pioniers/detailpagina-mobiel-def.jpg" alt="detail page mobile"/>
            </div>
        </div>
    </div>


    <div className="frame pioniers-frame">
        <div className="inner">
        </div>
        <div className="vertical-image">    
            <img src="/images/pioniers/detailpagina-mobiel-def.jpg" alt="detail page mobile"/>
        </div>

        <div className="vertical-text">
            <h3>Visual design</h3>
            <p>There are multiple Pioneers editions each year. Every edition has a designated primary color. I used colored dots on the map and throughout the website in backgrounds and labels. It’s featured in the submission form and the pioneers-detail page.
            </p>
            <p>The UI elements I kept clean and simple, so the pioneers can truly be the main focus of the site.
            </p>
            <a className="project-button" href="https://pioniers.op.vpro.nl/pioniers" target="_blank" rel="noreferrer"> visit project </a>
        </div>
    </div>
    <a className="next" href="dustin">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default PioniersPage;