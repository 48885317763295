import React from 'react';

const BaltanPage = props => {
  return (
<div className="de-stort-page">
    <div className="container">
        <h1>Baltan</h1>
        <p className="intro">It’s challenging to develop a website for a company like Baltan that has multiple focuses in its research, development and organization areas. Baltan, which got its inspiration from Dick Raaijmakers’ work, is all about experimenting and inspiring ideas that close the gaps between science, technology, art and design. 
        </p>
        <p className="intro-small">In an effort to show investors and visitors what Baltan truly is, the website design was broken down into two categories – Library and Events. By doing it in this manner, it would allow visitors to under why, how and what; giving them a feel for the company.
        </p>
        
        <div className="image-big">
            <img src="/images/eerste-schets.jpg" alt="sketch homepage"/>
        </div>
        <div className="middle">
            <h3>Library & events</h3>
            <p className="description-big">Website visitors get a quick look at Baltan’s latest posts, but can easily look at the various events and the whole library. This design setup gives visitors a stress-free way to navigate the website. It was done in this manner so visitors can quickly check out the Event page, Library page and About page without having to hunt for the information.
            </p>
            <p className="description-big">A lot of people get a case of choise-stress in stead of choise-freedom nowaydays, this is my counter attack towards that for the future. I want to inspire people to take at least 5 minutes a day and chill watching take in the motion.
            </p>
        </div>
    </div>
  
    <div className="image-big">
        <img src="/images/wireframe-library-events.jpg" alt="wireframe homepage"/>
    </div>
    <div className="image-big">
        <img src="/images/homemock-up.jpg" alt="mockup homepage"/>
    </div>

    <div className="vertical-container">
        <div className="vertical-image">    
            <img src="/images/detail-schets-kort.jpg" alt="detailpage schets"/>
        </div>
        <div className="vertical-text">
            <h3>Detail pages</h3>
            <p>In detail pages Baltan can post everything about an event such as dates, tickets and a line-up. They can also create articles that relate to their research. They can hand-order and shift content in the CMS, so every page is completely custom. During the design process it was important to make sure the custom features wouldn't confuse the website user. No matter the position of the blocks, the page needed to be completely legible for the user.
            </p>
            <a className="project-button" href="https://www.baltanlaboratories.org/" target="_blank" rel="noreferrer"> visit project </a>
        </div>
    </div>
    <a className="next" href="de-stort">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default BaltanPage;