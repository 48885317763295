import React from 'react';

const GraphicAnimationsPage = props => {
  return (
<div className="de-stort-page graphicanimation">
    <div className="container">
        <h1>Graphic Animations</h1>
        {/*<p className="intro">In my spare time I Experiment a lot with interactive animation. I want the user to experience a tiny bizarre world of freedom. Most of the animations are pretty in-your-face and are ment to suprise you.
        </p>
        <p className="intro-small">I think these type of animations aren&#x27;t applied enough in web design, animations aren&#x27;t just for feedback or to create a sleek website. They can suprise the user, which helps them remember the site in long-term.
        </p>*/}
        
        <div className="image-big">
            <img src="/images/skateboarder-animation-no-border-top.gif" alt="skateboarder animation"/>
        </div>
        {/*<div className="middle">
            <h3>Zen animations</h3>
            <p className="description-big">
                My zen animation projects were intented to create a digital space where people can just watch, listen, sit back and enjoy. I almost see it as a future television experience. You click and enjoy whatever is on.
            </p>
            <p className="description-big">A lot of people get a case of choise-stress in stead of choise-freedom nowaydays, this is my counter attack towards that for the future. I want to inspire people to take at least 5 minutes a day and chill watching take in the motion.
            </p>
        </div>*/}
    </div>
    <div className="image-big">
        {/*<img src="/images/animation-bike.gif" alt="bike animation"/>*/}
        <img src="/images/animation-bike3-final.gif" alt="bike in city animation"/>
    </div>
{/*
    <div className="vertical-container">
        <div className="vertical-image">    
            <img src="/images/skateboarder-animation.gif" alt="skateboarder animation  in alleyway"/>
        </div>
        <div className="vertical-text">
            <h3>Take in the motion</h3>
            <p>
            A lot of people get a case of choice-stress in stead of choice-freedom nowaydays, this is my counter attack towards that for the future.</p>
            <p>I Want to inspire people to take at least 5 minutes a day and chill watching take in the motion.
            </p>
            <a className="project-button" href="https://hzt.nl" target="_blank"> visit project </a>
        </div>
    </div>*/}

    <div className="container">
      <div className="work-wrapper">
        <section>
          <div className="video-wrapper">
            <div className="video-big">
              {/* <a class="work mobileAbout"><div class="caption projectmenu"><h2>About</h2><p>Why, How, What?</p></div></a>    398!*/}
              <iframe width={1120} height={518} src="https://www.youtube.com/embed/AeiiP6ZNd1Y?VQ=HD720&rel=0&showinfo=0" title="animation and motion reel" frameBorder={0} allowFullScreen />
            </div>
          </div>
          <div style={{"clear":"both","padding-top":"40px"}} />
          <div id="videogrid">
            <div className="column-left">
              <div className="gif">
                <img src="/images/animations/epldot.gif" alt="psychedelic cell animation green yellow"/>
                {/*<iframe src="http://giphy.com/embed/CS3AIzVP7gPwA" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/CS3AIzVP7gPwA"></a></p>!*/}
              </div>
            </div>
            <div className="column-right">
              <div className="gif">
                <img src="/images/animations/eplsquare.gif" alt="psychedelic squares animation" />
                {/*<iframe src="http://giphy.com/embed/3o6ZtezOehfIOHNSYU" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/3o6ZtezOehfIOHNSYU"></a></p>!*/}
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column-left">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/5.jpg" alt="jumping black and white photograph" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div className="column-right">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/9.jpg" alt="anarchy punk photgraph" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column-left">
              <div className="video-wrapper anim">
                <iframe src="https://player.vimeo.com/video/134894399" title="Truckfighters live band recap" width={560} height={349} frameBorder={0} webkitallowfullscreen mozallowfullscreen allowFullScreen />
              </div>
            </div>
            <div className="column-right">
              <div className="gif">
                {/*<img src="/images/gif/plantpsych.gif"/>!*/}
                {/*<iframe src="http://giphy.com/embed/yGkGZmWQcaXni" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/yGkGZmWQcaXni"></a></p>!*/}
                <div>
                  <div style={{"left":"0px","width":"100%","height":"0px","position":"relative","padding-bottom":"99.6%"}}><iframe src="https://giphy.com/embed//yGkGZmWQcaXni/twitter/iframe" title="organic hypnotic animation" frameBorder={0} allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" style={{"top":"0px","left":"0px","width":"100%","height":"100%","position":"absolute"}} /></div>
                </div>
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column">
              <div className="video-wrapper " style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/3.jpg" alt="photograph of shilhouettes walking in front of a lighting installation" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column-left">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/6.jpg"  alt="skateboarding and flying skateboard" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div className="column-right">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/2.jpg" alt="High contrast band on stage" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column-left">
              <div className="video-wrapper anim">
                <iframe width={560} height={349} src="https://www.youtube.com/embed/tAdGLwov6pE?VQ=HD720&rel=0&showinfo=0" title="glow animation" frameBorder={0} allowFullScreen />
              </div>
            </div>
            <div className="column-right">
              <div className="gif">
                {/*<img src="/images/gif/blocks.gif"/>!*/}
                <div>
                  <div style={{"left":"0px","width":"100%","height":"0px","position":"relative","padding-bottom":"99.6%"}}><iframe src="https://giphy.com/embed//l0HlEPm6j7Mq3bHSE/twitter/iframe" title="geometric pattern animation" frameBorder={0} allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" style={{"top":"0px","left":"0px","width":"100%","height":"100%","position":"absolute"}} /></div>
                </div>
                {/*<iframe src="http://giphy.com/embed/l0HlEPm6j7Mq3bHSE" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>*/}
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column">
              <div className="video-wrapper" style={{"height":"auto","padding":"0 0"}}>
                <img src="/images/animations/7.jpg" alt="graffity and street art in alley way" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column-left">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/4.jpg" alt="skateboarder" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div className="column-right">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/11.jpg" alt="high contrast lighting and glass" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column-left">
              <div className="photowrapper">
                <img src="/images/animations/afmsquare.gif" alt="logo animation all future memories" />
                {/*<iframe src="http://giphy.com/embed/iAzXEuw4nCIr6" width="480" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>!*/}
              </div>
            </div>
            <div className="column-right">
              <div className="photowrapper">
                <img src="/images/animations/ceEindhoven.gif" alt="logo animation cultuur eindhoven"/>
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/12.jpg" alt="window in the wall" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column-left">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/10.jpg" alt="skyscape buildings" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div className="column-right">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/8.jpg" alt="blackened candy" style={{"max-width":"100%"}} />
              </div>
            </div>
            <div style={{"clear":"both"}} />
            <div className="column-left bottom-pic">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/1.jpg" alt="performer dancing high contrast" />
              </div>
            </div>
            <div className="column-right">
              <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
                <img src="/images/animations/14.jpg" alt="dancing" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <a className="next" href="boz">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default GraphicAnimationsPage;