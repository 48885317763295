import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

const GraphicAnimations = () => {
  const [open, setOpen] = React.useState(false);
  return ( //ref={this.myRef}
    <div data-section-name="dustin" className="thirdSlide graphic" id="graphicanimations">
      <div className={["stort section snap project overlap animate boz", open ? "open" : null].join(" ")}>
        <div className="projecttext">
          <div className="projectmarquee">
            <div className="menu__item">
             <a href="graphicanimations" className="menu__item-link" 
              onMouseEnter={() => { setOpen(!open);}} onMouseLeave={() => { setOpen(!open);}}>
             Graphic<br />Animations</a>
              <div className="marquee">
                <div className="marquee__inner" aria-hidden="true">
                  <span>Graphic<br />Animations</span>
                  <span>Graphic<br />Animations</span>
                  <span>Graphic<br />Animations</span>
                  <span>Graphic<br />Animations</span>
                </div>
              </div>
            </div>
          </div>
          <div className="description side">
            <h4>Animations & photography</h4>
          </div>
        </div>
        <div className="image-background">
        </div>
        

        {/*<div id="first" className="ball bigger">
          <div className="eye">
               <div className="pupil"></div>
          </div>

          <div className="eyeright">
               <div className="pupil"></div>
          </div>
      </div>*/}


      </div>
    </div>
  );
};


export default GraphicAnimations;