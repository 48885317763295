import React from 'react';
import { Link } from 'react-router-dom';

const Animate = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="seventhSlide" id="animate">
      <div data-section-name="animate" className={["section snap project overlap animate", open ? "open" : null].join(" ")}>
        <div className="image-right">
        </div>
        <div className="projecttext">
          <div className="projectmarquee">
            <div className="menu__item">
              <a href="animate" className="menu__item-link"
              onMouseEnter={() => { setOpen(!open);}} onMouseLeave={() => { setOpen(!open);}}>
              Animate</a>
              <div className="marquee">
                <div className="marquee__inner" aria-hidden="true">
                  <span>Animate</span>
                  <span>Animate</span>
                  <span>Animate</span>
                  <span>Animate</span>
                </div>
              </div>
            </div>
          </div>
          <div className="description side">
            <h4>interactive animations, ranging from psychedelic<br />to zen</h4>
            <h5>user exprience, interaction design &amp; development</h5>
          </div>
        </div>
        <div className="image-right small">
        </div>
      </div>
    </div>
  );
};

export default Animate;