import React from 'react';
import { DeStort } from '../../components/DeStort';
import { Program } from '../../components/Program';
import { Hzt } from '../../components/Hzt';
import {Animate} from '../../components/Animate';
import {Baltan} from '../../components/Baltan';
import {Boz} from '../../components/Boz';
import {Pioniers} from '../../components/Pioniers';
import {Dustin} from '../../components/Dustin';
import {GraphicAnimations} from '../../components/GraphicAnimations';

import {ActiveScroll} from '../../components/ActiveScroll';

const Work = props => {
	  return (
      <div className="wrapper">
        <Pioniers> </Pioniers>
        <Dustin> </Dustin>
        <GraphicAnimations> </GraphicAnimations>
        <Boz> </Boz>
        <Program> </Program>
        <Hzt> </Hzt>
        <Animate></Animate>
        <Baltan> </Baltan>
        <DeStort> </DeStort>
        <ActiveScroll> </ActiveScroll>
      </div>

  );

};

export default Work;