import React from 'react';

const ProgramGuide = props => {
  return (
<div className="de-stort-page program-guide-page">
    <div className="container">
    	<h1>Program Guide</h1>
      	<p className="intro">The main goal of this project was to create a user-friendly and responsive program guide for VPRO. The previous guide scrolled horizontally, which was frustrating for many of its users.</p>
    	<p className="intro-small">The use of color blocks with low-contrast and obtrusive user interface elements also diminished the usability of the guide.</p>
        
        <div className="image-big">
        	<img src="/images/first-prototype-desktop.jpg" alt="first prototype"/>
        </div>

        <div className="middle">
            <h3>Wireframes & usertesting</h3>
            <p className="description-big">To create a new guide and be able to test it quickly, I started sketching and prototyping on paper.  After creating wireframes based on these paper-protypes, I started to create a mock-up. To prototype I used UXPin, which I mailny used to test the mobile version. The desktop was protyped in code, so we could test two different variants of the guide.
            </p>
            <div className="image-big">
                <img src="/images/programmagids.gif" alt="guide"/>
            </div>   
        </div>
    </div>

    <div className="vertical-container">
        <div className="vertical-image">    
            <img src="/images/mobile-gids-z-outline.jpg" alt="mobile guide"/>
        </div>
        <div className="vertical-text">
            <h3>Visual design</h3>
            <p>Because the previous UI was quite messy, I deceided to take a clean interface approach, with minimal color, monotone based. This was a rigorous design designing compared to the previous design system. I also chose to introduce the Simplistic Sans regular to the website, for the description tekst. Using the outline button, not only was a recognisable shape for the user, it also paired well with the new Outline logo VPRO and Thonik introduced in 2019.
            </p>
            <a className="project-button" href="https://www.vprogids.nl/programmagids.html" target="_blank" rel="noreferrer"> visit project </a>
        </div>
    </div>
    <a className="next" href="hzt">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default ProgramGuide;