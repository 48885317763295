import React from 'react';

const BozPage = props => {
  return (
<div className="de-stort-page boz">
    <div className="container">
        <h1>Brommer op Zee</h1>
        <p className="intro">Create a small community, to dicuss books with other reads and the author. Read a free book every month, with annotations from the authors perspective. Discuss remarks and the book with other readers.</p>
        <p className="intro-small">A complete redesign, in functionality, purpose, usability and visual design. Each design stage including concepts, wireframes, mock-ups and visual design had multiple iterations to ensure usability.</p>
        
        <div className="squares">
            <div className="square">
                <img src="/images/boz-home.jpg" alt="home app"/>
            </div>
            <div className="square">
                <img src="/images/boz-lees-blauw.jpg" alt="read app"/>
            </div>
            <div className="square">
                <img src="/images/boz-notitie.jpg" alt="remark host app"/>
            </div>
        </div>

        <div className="squares">
            <div className="square">
                <img src="/images/home-wireframe.jpg" alt="home app"/>
            </div>
            <div className="square">
                <img src="/images/lees-wireframe.jpg" alt="read app"/>
            </div>
            <div className="square">
                <img src="/images/notitie-wireframe.jpg" alt="remark host app"/>
            </div>
        </div>

        <div className="middle">
            <h3>Writer's annotations</h3>
            <p className="description-big">Every month there’s a new book, filled with annotations from the writer. Each annotation is marked with a colored marker. When tapping on the marked text, the annotation screen is opened filled with personal notes from the writer. The user can comment on a personal note and connect with the writer and other readers.  
            </p>
            <h3>User testing</h3>
            <p className="description-big">Usability of the app was tested among a range of ages. It was important that the older audience could also enjoy the app without any issues. The app needed to be accessible and easy to learn. Feedback from the testers was applied in new iterations of the design.
            </p>
        </div>  
    </div>

    <div className="frame">
        <div className="inner">
        </div>
        <div className="vertical-image">    
            <img src="/images/boz-animation.gif" alt="scooter animation"/>
        </div>

        <div className="vertical-text">
            <h3>Visual design</h3>
            <p>The wave I used throughout the app fit the title “Brommer op Zee - Moped on sea”. The shape of the markers in the pages also have an organic, sketched feel. For the splashscreen I illustrated and made several animations. 
            </p>
            <p>
            </p>
            <a className="project-button" href="https://apps.apple.com/nl/app/brommer-op-zee/id1544965283" target="_blank" rel="noreferrer"> visit project </a>
        </div>
    </div>

    <a className="next" href="vpro-program-guide">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default BozPage;