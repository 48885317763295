import React from 'react';

const HztPage = props => {
  return (
<div className="de-stort-page hzt-page">
    <div className="container">
    	<h1>Zuidelijk Toneel</h1>
      	<p className="intro">Het Zuidelijk Toneel (HZT) is a leading versatile theater house. I was to redesign, wireframe and construct the structure and flow of their website in an attempt to improve its website, attract more traffic and improve ease of use.</p>
    	<p className="intro-small">The workshop I kicked the project off by finding features to improve the website. These new features helped me to:
        <ul className="list">
            <li>Turn a complex website into a user-friendly one</li>
            <li>Create wireframes of programmer that offers complete customizability for clients.</li>
            <li>Create easily distinguishable blocks for users.</li>
        </ul>
        </p>
        
        <div className="image-big">
        	<img src="/images/hzt-overview.jpg" alt="calendar mock-up"/>
        </div>
        <div className="middle">
            <h3>Usertesting</h3>
            <p className="description-big">
                After scoping out the want and needs of HZT into wireframes, I started testing these wireframes with users. The tests started out by interviewing the users on their use of theater website, as well as asking for a first impression of the prototype screens. I gave the users different tasks and goals to accomplish. After each task users had to answer the The Single Ease Question (SEQ) scale, to see which tasks were the hardest to complete.
            </p>
            <p className="description-big">To pinpoint any usability problem the tasks didn't clear up, each user test was finalized with a SUS questionnaire as well as a Questionnaire for User Interface Satisfaction (QUIS). Many problems became clear instantly, like the need for a search bar, that was never even apart of the website or prototype before. Another test displayed that the ticket link wasn't clear for users, which is shown in the video down below.
            </p>
        </div>
    </div>
    {/*<div className="video w-video w-embed"><iframe className="embedly-embed" src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fplayer.vimeo.com%2Fvideo%2F286064599%3Fapp_id%3D122963&dntp=1&url=https%3A%2F%2Fplayer.vimeo.com%2Fvideo%2F286064599&image=http%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F720875919_1280.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=vimeo" scrolling="no" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe></div> */} 
    {/*<div className="image-big">
        <img src="/images/hzt-calendar.jpg" alt="globes bright color"/>
    </div>*/}

    <div className="container">
         <div className="squares wide">
            <div className="square">
                <img src="/images/hzt-detailpage-visual.jpg" alt="detailpagina desktop"/>
            </div>
            <div className="square">
                <img src="/images/hzt_opzetdetail.jpg" alt="detail page mobile"/>
            </div>
        </div>
    </div>

    <div className="vertical-container">
        <div className="vertical-image">    
            <img src="/images/hzt-wireframe-home.png" alt="globes bright color"/>
        </div>
        <div className="vertical-text">
            <h3>Wireframes & sitemap</h3>
            <p>
            Understanding these ideas is a great way to start off, overcome fears and get everyone involved in the project on the same page. A day of collaboration with the client offered invaluable help when it came to their goals, understanding then, how I can help them and current pain points. We had a goal of turning the complex website into a user-friendly one and giving complete access and customization to HZT.
            </p>
            <a className="project-button" href="https://hzt.nl" target="_blank" rel="noreferrer"> visit project </a>
        </div>
    </div>
    <a className="next" href="animate">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default HztPage;