import React, {useEffect, useState } from "react";
import './ActiveScroll.scss';
import { windowDimensions } from '.././windowDimensions';

function ActiveScroll() {
const { height, width } = windowDimensions();
  const [show, doShow] = useState({
    itemOne: true,
    itemTwo: false,
    itemThree: false,
    itemFour: false,
    itemFive: false,
    itemSix: false,
    itemSeven: false,
    itemEight: false,
    itemNinth: false,
    icon: true
  });
const [scroll, setScroll] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {

    if(window.scrollY > 50){
      console.log('icon');
      doShow((state) => ({ ...state, icon: false}));
    }

    if (window.scrollY < height ) {
      console.log('1 ' + height)
      doShow((state) => ({ ...state, itemOne: true, itemTwo: false, itemThree: false, itemFour: false, itemFive:false, itemSix: false, itemSeven: false, itemEight: false, itemNinth: false }));
      } else if (window.scrollY > height && window.scrollY < height * 2) {
        console.log('2')
         doShow((state) => ({ ...state, itemOne: false, itemTwo: true, itemThree: false, itemFour: false, itemFive:false, itemSix: false, itemSeven: false, itemEight: false, itemNinth: false, icon: false }));
      } else if (window.scrollY > height * 2 && window.scrollY < height * 3) {
        console.log('3')
        doShow((state) => ({ ...state, itemOne: false, itemTwo: false, itemThree: true, itemFour: false, itemFive:false, itemSix: false, itemSeven: false, itemEight: false, itemNinth: false }));
      } else if (window.scrollY > height * 3 && window.scrollY < height * 4) {
        console.log('4')
        doShow((state) => ({ ...state,  itemOne: false, itemTwo: false, itemThree: false, itemFour: true, itemFive:false, itemSix: false, itemSeven: false, itemEight: false, itemNinth: false }));
      } else if (window.scrollY > height * 4 && window.scrollY < height * 5) {
        console.log('5')
        doShow((state) => ({ ...state, itemOne: false, itemTwo: false, itemThree: false, itemFour: false, itemFive: true, itemSix: false, itemSeven: false, itemEight: false, itemNinth: false }));
      } else if (window.scrollY > height * 5 && window.scrollY < height * 6) {
        console.log('6')
        doShow((state) => ({ ...state, itemOne: false, itemTwo: false, itemThree: false, itemFour: false, itemFive: false, itemSix: true, itemSeven: false, itemEight: false, itemNinth: false }));
      } else if (window.scrollY > height * 6 && window.scrollY < height * 7) {
        console.log('7')
        doShow((state) => ({ ...state, itemOne: false, itemTwo: false, itemThree: false, itemFour: false, itemFive: false, itemSix: false, itemSeven: true, itemEight: false, itemNinth: false}));
      } else if (window.scrollY > height * 7 && window.scrollY < height * 8) {
        console.log('8')
        doShow((state) => ({ ...state, itemOne: false, itemTwo: false, itemThree: false, itemFour: false, itemFive: false, itemSix: false, itemSeven: false, itemEight: true, itemNinth: false }));
      } else if (window.scrollY > height - 30 * 8 && window.scrollY < height * 9) {
        console.log('8')
        doShow((state) => ({ ...state, itemOne: false, itemTwo: false, itemThree: false, itemFour: false, itemFive: false, itemSix: false, itemSeven: false, itemEight: false, itemNinth: true }));
        }
   });
 }, [])
 return (
  <div>
    <ul className="pagination w-list-unstyled">
      <li><a href="#pioniers" className={"link-block w-inline-block " + (show.itemOne ? "active" : "non")} /></li>
      <li><a href="#dustin" className={"link-block w-inline-block " + (show.itemTwo ? "active" : "non")} /></li>
      <li><a href="#graphicanimations" className={"link-block w-inline-block " + (show.itemThree ? "active" : "non")} /></li>
      <li><a href="#boz" className={"link-block w-inline-block " + (show.itemFour ? "active" : "non")}/></li>
      <li><a href="#program-guide" className={"link-block w-inline-block " + (show.itemFive ? "active" : "non")}/></li>
      <li><a href="#hzt" className={"link-block w-inline-block " + (show.itemSix ? "active" : "non")}/></li>
      <li><a href="#animate" className={"link-block w-inline-block " + (show.itemSeven ? "active" : "non")}/></li>
      <li><a href="#baltan" className={"link-block w-inline-block " + (show.itemEight ? "active" : "non")}/></li>
      <li><a href="#de-stort" className={"link-block w-inline-block " + (show.itemNinth ? "active" : "non")}/></li>
    </ul>
    <img src="/images/arrow-down.svg" alt="arrow down icon" className={"icon " + (show.icon)}/>
  </div>
  )
}
export default ActiveScroll;