import React from 'react';
import { Link } from 'react-router-dom';
import './DeStort.scss';

const DeStort = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="ninthSlide" id="de-stort">
      <div data-section-name="de-stort" className={["stort section snap project overlap", open ? "open" : null].join(" ")}>
        <div className="projecttext">
          <div className="projectmarquee">
            <div className="menu__item">
              <a href="de-stort" className="menu__item-link" 
              onMouseEnter={() => { setOpen(!open);}} onMouseLeave={() => { setOpen(!open);}}>
              De Stort</a>
              <div className="marquee">
                <div className="marquee__inner" aria-hidden="true">
                  <span>De Stort</span>
                  <span>De Stort</span>
                  <span>De Stort</span>
                  <span>De Stort</span>
                </div>
              </div>
            </div>
          </div>
          <div className="description side">
            <h4>interactive animations, ranging from psychedelic to zen</h4>
            <h5>user experience, interaction design &amp; development</h5>
          </div>
        </div>
        <div className="image-right">
        </div>
        <div className="image-right small">
        </div>
      </div>
    </div>
  );
};

export default DeStort;