import React from 'react';
import { Link } from 'react-router-dom';

import { NavLink } from 'react-router-dom';


import './NavBar.scss';

const NavBar = () => {
  return (
    <nav role="navigation">
       {/*} <ul>
        	<li className={ window.location.pathname === "/work" ? "active" : "" }><Link to="/work">Work</Link></li>
        	<li className={ window.location.pathname === "/about" ? "active" : "" }><Link to="/about">About</Link></li>
     	</ul>*/}
     	<ul>
     	    <li> <NavLink to='work' activeClassName="active">work</NavLink></li>
     	   {/* <li> <NavLink to='about' activeClassName="active">about</NavLink></li>*/}
     	</ul>
    </nav>
  );
};

export default NavBar;

        //<li><Link to="/Home">Home</Link></li>
