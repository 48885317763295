import React from 'react';

const About = props => {
  return (
<div className="de-stort-page about">
    {/* <div className=""><img src="images/linda-hoekstra.png" alt="image person jumping"/></div>*/}
	<div className="about-grid">
		
		<div className="grid-3">
		    <div className="vertical-image">    
		        {/*<img src="/images/linda-hoekstra.png" alt="globes bright color image"/>*/}
		    </div>

			<div className="marquee">
				<div className="marquee__inner" aria-hidden="false">
				    <span>N</span>
				    <span>O</span>
				    <span>T</span>
				    <span>I</span>
				    <span>C</span>
				    <span>E</span>
				    <span>M</span>
				    <span>E</span>
				    <span>N</span>
				    <span>O</span>
				    <span>T</span>
				    <span>I</span>
				    <span>C</span>
				    <span>E</span>
				    <span>M</span>
				    <span>E</span>
				</div>
			</div>
		</div>
	    <div className="vertical-text">
	            <h3>DESIGN FOR PURPOSE</h3>
	            <p>
	            I'm a 30 year old interaction designer from the Netherlands. My interests reign from user experience design to visual design. I have a background in front and back-end development, which means I can design for realisation and can discuss with developers quickly and efdiciently. 
	            <br/>My main focus is always designing for the user, but having this solid background and understanding of dev work, enables me to design and solve problems efficiently. 
</p>
<p>I have a passion for functional design with a little extra. I love to iterate, test and push design work to become better. I believe in experimenting with designs and technical implementation, using prototypes.
</p>
<p>
I'm looking to work with a company that also believes in this need for experimenting. <br/>
The products I work on should improve the lives of the people using them. Impact their day in a good way. All my work is focused on social and cultural projects. In the future I want to continue to have a positive impact with my work.
	            </p>
	            <a className="project-button" href="hoekstralinda@gmail.com" target="_blank"> send email </a>
	        </div>
	</div>

   {/* <a className="next" href="vpro-program-guide.html">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a> */}
</div>
  );
};

export default About;