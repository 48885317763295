import React from 'react';
import { Link } from 'react-router-dom';

const Baltan = () => {
  const [open, setOpen] = React.useState(false);
  return ( //ref={this.myRef}
    <div data-section-name="baltan" className="eighthSlide" id="baltan">
      <div className={["section snap project program baltan", open ? "open" : null].join(" ")}>
        <div className="projecttext">
          <div className="projectmarquee">
            <div className="menu__item">
              <a href="baltan" className="menu__item-link" 
              onMouseEnter={() => { setOpen(!open);}} onMouseLeave={() => { setOpen(!open);}}>
              Baltan<br />Laboratories</a>
              <div className="marquee">
                <div className="marquee__inner" aria-hidden="true">
                  <span>Baltan<br />Laboratories</span>
                  <span>Baltan<br />Laboratories</span>
                  <span>Baltan<br />Laboratories</span>
                  <span>Baltan<br />Laboratories</span>
                </div>
              </div>
            </div>
          </div>
          <div className="description side">
            <h4>website for a multidisciplinary lab</h4>
            <h5>user experience design &amp; development</h5>
          </div>
        </div>
        <div className="image-right small">
        </div>
      </div>
    </div>
  );
};

export default Baltan;