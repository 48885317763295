import React from 'react';
import './DeStortPage.scss';

const DeStortPage = props => {
  return (
<div className="de-stort-page">
    <div className="container">
    	<h1>De Stort</h1>
      	<p className="intro">A placeholder, the perfect time to already show a glimps of what the website will be all about. It&#x27;s also great way to motivate and inspire any starting company to go ahead and create a special digital experience with care.</p>
    	<p className="intro-small">The interaction is simple, yet giving the user the opportunity to be surpised by the next animation. I took three days to create this project, from sketch, to well quick-coding. It&#x27;s a good example of what I can do within harsh time constraints, and no previous work or concepts.</p>
        
        <div className="squares">
            <div className="square"></div>
            <div className="square-small"></div>
        </div>
        <div className="image-big">
        	<img src="/images/de-stort-screen.png" alt="globes bright color"/>
        </div>

        <div className="middle">
            <h3>Surprise the user</h3>
            <p className="description-big">It's always a challenge to surprise the user. I wanted to create some easter eggs in the website, which ended up being two animations which a user would encouter on-click.  
To give a sneaky glimpse of what the studio is all about in a fun way. The website itself is based on experimentation in design, code and with the user interaction. Every visitor can experience a different part of the site, based on the easter eggs. Not everyone will discover each part of the site.
            </p>
        </div>   
    </div>

    <div className="vertical-container">
        <div className="vertical-image">    
            <img src="/images/de-stort-vertical3.png" alt="globes bright color"/>
        </div>
        <div className="vertical-text">
            <h3>Experimenting with typography</h3>
            <p>After creating the base of the project, featuring globes and neon text, I decided that the website should be more dynamic. I created two more experiments using type and animation, for a user could click through and enjoy.
            </p>
            <p>Taking this heavy font approach as a statement, the globes actually started to take a backseat.  Also the animation of text, became a subject in the third experiment.
            </p>
            <a className="project-button" href="https://destort.nl" target="_blank" rel="noreferrer"> visit project </a>
        </div>
    </div>
    <a className="next" href="pioniers">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default DeStortPage;