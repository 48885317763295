import React from 'react';

const DustinPage = props => {
  return (
<div className="de-stort-page boz pioniers dustin">
    <div className="container">
        <h1>Dustin</h1>
        <p className="intro">Smart Roomba with feelings
        </p>
        <p className="intro-small">In this augmented reality app you’ll meet Dustin. Dustin is a roomba with a few errors. The user receives dustin as a free to test product. He’s sick of having to clean every single day and wants more out of life. You can befriend Dustin and help him make his dreams come true, or treat him like a machine. The user can play and talk with Dustin at their location. 
</p>
        
        <div className="squares">
            <div className="square">
                <img src="/images/dustin/dustin-cover-retina.jpg" alt="dustin coverscreen"/>
            </div>
            <div className="square">
                <img src="/images/dustin/foto-opslaan.jpg" alt="save picture screen"/>
            </div>
            <div className="square">
                <img src="/images/dustin/dustin-instructies.png" alt="instructions"/>
            </div>
        </div>


        <div className="middle">
            <h3>Testing</h3>
            <p className="description-big">The app was tested during Dutch Design Week and during a test-day in Groningen Forum, as well as in-house testing. Based on results and feedback, I was able to improve the UI and user experience.
            </p>
            <p className="description-big">We tested the game on a large demographic, ranging from children to elderly, to test who would be able to use and play the game in AR.
            </p>
        </div>
    </div>
    <div className="container">  
        <div className="image-big">
            <img src="/images/dustin/test-forum-groningen.jpg" alt="usertest het Forum Groningen"/>
        </div>
        <div className="image-big">
            <img src="/images/dustin/test-ddw.jpg" alt="usertest Dutch Design Week"/>
        </div>
    </div>

    <div className="container">
        <div className="squares double">
            <div className="square">
                <img src="/images/dustin/chat-dustin.png" alt="chat with dustin"/>
            </div>
            <div className="square">
                <img src="/images/dustin/stofzuigen-sterren.png" alt="vaccuum with dustin"/>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="squares wide">
            <div className="square">
                <img src="/images/dustin/screen-forum-groningen.jpg" alt="app preview"/>
            </div>
            <div className="square">
                <img src="/images/dustin/dustin-eind-scherm2.png" alt="end screen"/>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="squares wide">
            <div className="square">
                <img src="/images/dustin/screen-app-test.jpg" alt="app test dustin vaccuming"/>
            </div>
            <div className="square">
                <img src="/images/dustin/dustin-AR.png" alt="registration form mobile"/>
            </div>
        </div>
    </div>


    <div className="frame">
        <div className="inner">
        </div>
        <div className="vertical-image">    
            <img src="/images/dustin/animatie-eindscherm.gif" alt="endscreen animation concept"/>
        </div>

        <div className="vertical-text">
            <h3>UI</h3>           
            <p>
            The UI was kept simple and clean, to create minimal distractions as AR can already be a complication for a user. Earlier iterations were purely based on usability, and we tested the user interaction with a black and white interface. 
            </p>
            <p>
            During the visual design process, I hand drew a lot of the interface. I wanted the UI to feel personable, to feel like Dustin created it himself. The visual design stays clear from any AI-design clichés.
            </p>
            <p>
            To give an incentive for The user to vacuum a space repeatedly and to create a sense of competitiveness. I Added stars for every cloud of dust a user vacuums during the game. 
            </p>
            <a className="project-button" href="https://apps.apple.com/us/app/dustin/id1491331550" target="_blank" rel="noreferrer"> visit project </a>
        </div>
    </div>
    <a className="next" href="graphicanimations">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default DustinPage;