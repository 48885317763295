import React from 'react';

const AnimatePage = props => {
  return (
<div className="de-stort-page">
    <div className="container">
        <h1>Interactive Animations</h1>
        <p className="intro">In my spare time I Experiment a lot with interactive animation. I want the user to experience a tiny bizarre world of freedom. Most of the animations are pretty in-your-face and are ment to suprise you.
        </p>
        <p className="intro-small">I think these type of animations aren&#x27;t applied enough in web design, animations aren&#x27;t just for feedback or to create a sleek website. They can suprise the user, which helps them remember the site in long-term.
        </p>
        
        <div className="image-big">
            <img src="/images/come-closer.png" alt="graphic text come closer"/>
        </div>
        <div className="middle">
            <h3>Zen animations</h3>
            <p className="description-big">
                My zen animation projects were intented to create a digital space where people can just watch, listen, sit back and enjoy. I almost see it as a future television experience. You click and enjoy whatever is on.
            </p>
            <p className="description-big">A lot of people get a case of choise-stress in stead of choise-freedom nowaydays, this is my counter attack towards that for the future. I want to inspire people to take at least 5 minutes a day and chill watching take in the motion.
            </p>
        </div>
    </div>
    <div className="image-big">
        <img src="/images/visuals-thumbnail2a.jpg" alt="gradient globes animation"/>
    </div>

    <div className="vertical-container">
        <div className="vertical-image">    
            <img src="/images/animate-vertical-image-blue.png" alt="abstract ocean graphic"/>
        </div>
        <div className="vertical-text">
            <h3>Take in the motion</h3>
            <p>
            A lot of people get a case of choice-stress in stead of choice-freedom nowaydays, this is my counter attack towards that for the future.</p>
            <p>I Want to inspire people to take at least 5 minutes a day and chill watching take in the motion.
            </p>
            <a className="project-button" href="http://psychedelicbaby.com/projects/lollygag" target="_blank" rel="noreferrer"> visit project </a>
        </div>
    </div>
    <a className="next" href="baltan">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default AnimatePage;